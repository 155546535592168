import { parseISO, format, isBefore, isValid, parse } from 'date-fns';

const dateFormatString = 'yyyy-MM-dd';

export type ConstraintErrors = {
  ctyhocn: boolean;
  groupCode: boolean;
  arrivalDate: boolean;
  departureDate: boolean;
  [key: string]: boolean;
};

export type QueryParameters = {
  ctyhocn?: string;
  groupCode?: string;
  arrivalDate?: string;
  departureDate?: string;
  step?: string;
  edit?: boolean;
  userType?: string;
  event?: string;
  [key: string]: string | boolean | number | string[] | undefined;
};

export const defaultConstraintErrors: ConstraintErrors = {
  ctyhocn: false,
  groupCode: false,
  arrivalDate: false,
  departureDate: false,
};

export const arrivalDateParams = ['arrival', 'arrivaldate', 'arrivalDate', 'fromDate'];

export const departureDateParams = ['departure', 'departuredate', 'departureDate', 'toDate'];

const isInvalidDate = (dateInString: string): boolean => {
  const format1Date = parse(dateInString, 'yyyy-MM-dd', new Date());
  const format2Date = parse(dateInString, 'yyyyMMdd', new Date());
  return isValid(format1Date) ? false : !isValid(format2Date);
};

export const validateAndFormatArrivalDepartureDates = (
  parsedQueryParams: QueryParameters,
  constraintErrors: ConstraintErrors
) => {
  const { arrivalDate, departureDate } = parsedQueryParams;
  const todaysDate = format(new Date(), dateFormatString);

  if (
    arrivalDate &&
    departureDate &&
    (isInvalidDate(arrivalDate) ||
      isInvalidDate(departureDate) ||
      isBefore(parseISO(departureDate), parseISO(arrivalDate)) ||
      isBefore(parseISO(arrivalDate), parseISO(todaysDate)))
  ) {
    delete parsedQueryParams['arrivalDate'];
    delete parsedQueryParams['departureDate'];
    constraintErrors.arrivalDate = true;
    constraintErrors.departureDate = true;
    return;
  }

  parsedQueryParams.arrivalDate = arrivalDate && format(parseISO(arrivalDate), dateFormatString);
  parsedQueryParams.departureDate =
    departureDate && format(parseISO(departureDate), dateFormatString);
};
