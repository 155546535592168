import { useEffect } from 'react';
import * as React from 'react';
import { Header as SharedHeader } from '@dx-ui/osc-header';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { env } from '@dx-ui/framework-env';
import { useEventMetrics, MetricsEvent } from '../../hooks';
import { useRouter } from 'next/router';
import { SessionContext, SessionContextTypes } from '../../lib';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useIsClient } from 'usehooks-ts';
import { useHeaderLinks } from '../../hooks/useHeaderLinks';

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const router = useRouter();
  const [isMetricsSent, setMetricSent] = React.useState(false);
  const { setSessionContext } = React.useContext(SessionContext);
  const language = router.locale as string;
  const route = router.route;
  const { setEventMetrics } = useEventMetrics();
  const { isAuthenticated, guestId, guestInfo, isLoading, isCorpUser, login, logout } = useAuth();
  const isClient = useIsClient();

  const OHW_ORIGIN = new URL(env('OHW_BASE_URL', 'https://www.hilton.com/')).origin;
  const showSignInJoinLinks =
    isClient && !router.pathname.includes('/my-event/sign-in') && !isCorpUser;
  const { additionalLinks, honorsActivityUrl, findStayUrl } = useHeaderLinks();
  const isEventPage = route === '/attend-my-event/[event]';

  useEffect(() => {
    if (!isLoading && isAuthenticated && (guestId || -1) >= 0 && !isMetricsSent && guestInfo) {
      setMetricSent(true);
      setEventMetrics(MetricsEvent.LOG_IN, {
        hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
        tierName: guestInfo?.hhonors?.summary?.tierName || '',
        totalPoints: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
        packages: (guestInfo?.hhonors?.packages || []) as unknown as string[],
      });
    }
  }, [isLoading, isAuthenticated, guestId, guestInfo, isMetricsSent, setEventMetrics]);

  const handleSignout = async () => {
    await logout().finally(() => {
      switch (route) {
        case '/my-event/sign-in':
          setSessionContext(SessionContextTypes.SignOut);
          break;
        case '/attend-my-event/[event]':
          setSessionContext(SessionContextTypes.SignOutAndStayOnPage);
          break;
        default:
          setSessionContext(SessionContextTypes.ShowConfirmSignOut);
      }
      if (isBrowser && window?.sessionStorage) window.sessionStorage.clear();
    });
  };

  return (
    <SharedHeader
      showNewHeader={true}
      user={
        isAuthenticated && guestInfo && !isCorpUser
          ? {
              name: guestInfo.personalinfo?.name?.firstName,
              honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
              honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
              honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
              hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
            }
          : undefined
      }
      brand={{
        url:
          isClient && isCorpUser
            ? 'https://hilton.sharepoint.com/sites/LS_GlobalSales/SitePages/GME-Groups-Meetings-Events-Programs-Tools-Housing-Attendee-Website.aspx'
            : `${OHW_ORIGIN}/${language}/hilton-honors/guest/my-account/`,
        code: 'WW',
        name: 'Hilton',
      }}
      onSignOut={handleSignout}
      onSignInAttempt={async (response) => {
        if (response?.data) {
          await login(response);
        }
      }}
      userLinks={{
        accountLink: {
          url: `${OHW_ORIGIN}/${language}/hilton-honors/guest/my-account/`,
        },
        additionalLinks,
        ...(showSignInJoinLinks && {
          signInLink: {
            url: env('DX_AUTH_UI').replace('__LANG__', language),
          },
          signUpLink: {
            url: `${OHW_ORIGIN}/${language}/hilton-honors/join/`,
          },
        }),
      }}
      {...(isEventPage
        ? {
            findStayLink: {
              url: isAuthenticated ? honorsActivityUrl : findStayUrl,
              isNewWindow: true,
            },
          }
        : {})}
    />
  );
};

export default Header;
