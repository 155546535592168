import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { isBrowser } from '../lib';
import type { QueryParameters, ConstraintErrors } from '../utils/urlParserUtils';
import { hiltonlinkUrlParser } from '../utils/urlParser';

type UseWrappedRouter = {
  constraintErrors: ConstraintErrors;
  router: NextRouter;
  safeQueryParams: QueryParameters;
  querystring: string;
};
/**
 * This is an extension of next/router that returns server and client side safe values.
 * This is beacuse on server side render only a few specific query params/path variables are akamai safe.
 */
export function useWrappedRouter(): UseWrappedRouter {
  const router = useRouter();
  const queryParams = isBrowser
    ? { ...router.query, ...Object.fromEntries(new URLSearchParams(window.location.search)) }
    : router.query;
  const { parsedQueryParams, constraintErrors } = hiltonlinkUrlParser(queryParams);
  const querystring = new URLSearchParams(parsedQueryParams as Record<string, string>).toString();
  return {
    querystring,
    constraintErrors,
    router,
    safeQueryParams: { ...parsedQueryParams },
  };
}
