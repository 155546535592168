import { useContext } from 'react';
import type {
  CommonDataProps,
  SignInPageMetricsProps,
  CustomPageMetricsProps,
  ConfirmationPageMetricsProps,
  GroupSearchPageMetricsProps,
  CreatePageMetricsProps,
  PreviewPageMetricsProps,
  ErrorPageMetricsProps,
} from '../lib';
import { MetricsContext } from '../lib';

export const PageName = {
  SIGN_IN: 'sign-in',
  CREATE: 'create',
  GROUP_SEARCH: 'group-search',
  CONFIRMATION: 'confirmation',
  PREVIEW: 'preview',
  CUSTOM_PAGE: 'custom-page',
  ERROR_PAGE: 'error-page',
} as const;

export type PageNameType = (typeof PageName)[keyof typeof PageName];

type PageData<T> = T extends typeof PageName.GROUP_SEARCH
  ? GroupSearchPageMetricsProps
  : T extends typeof PageName.CREATE
  ? CreatePageMetricsProps
  : T extends typeof PageName.PREVIEW
  ? PreviewPageMetricsProps
  : T extends typeof PageName.CONFIRMATION
  ? ConfirmationPageMetricsProps
  : T extends typeof PageName.CUSTOM_PAGE
  ? CustomPageMetricsProps
  : T extends typeof PageName.SIGN_IN
  ? SignInPageMetricsProps
  : T extends typeof PageName.ERROR_PAGE
  ? ErrorPageMetricsProps
  : never;

export function usePageMetrics() {
  const { metrics } = useContext(MetricsContext);

  const setPageMetrics = <T extends PageNameType>(
    pageNameMetrics: T,
    commonData: CommonDataProps,
    pageData: PageData<T>
  ) => {
    metrics.setCommonData([commonData]);
    /* eslint-disable-next-line default-case */
    switch (pageNameMetrics) {
      case PageName.GROUP_SEARCH:
        metrics.setGroupSearchPageData([pageData as PageData<typeof PageName.GROUP_SEARCH>]);
        break;
      case PageName.CREATE:
        metrics.setCreatePageData([pageData as PageData<typeof PageName.CREATE>]);
        break;
      case PageName.PREVIEW:
        metrics.setPreviewPageData([pageData as PageData<typeof PageName.PREVIEW>]);
        break;
      case PageName.CONFIRMATION:
        metrics.setConfirmationPageData([pageData as PageData<typeof PageName.CONFIRMATION>]);
        break;
      case PageName.CUSTOM_PAGE:
        metrics.setCustomGroupPageData([pageData as PageData<typeof PageName.CUSTOM_PAGE>]);
        break;
      case PageName.SIGN_IN:
        metrics.setSignInPageData([pageData as PageData<typeof PageName.SIGN_IN>]);
        break;
      case PageName.ERROR_PAGE:
        metrics.setErrorPageData([pageData as PageData<typeof PageName.ERROR_PAGE>]);
        break;
      /* add case for other pages */
    }
  };

  return { setPageMetrics };
}
