import { useContext } from 'react';
import { CorpUserInfoContext } from '../lib';

export function useCorpUserInfo() {
  const context = useContext(CorpUserInfoContext);
  if (context === undefined) {
    throw new Error(`useCorpUserInfo must be used within a CorpUserInfoProvider`);
  }
  return context;
}
