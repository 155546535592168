import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import Header from '../../components/Header/Header';
import { Footer } from '@dx-ui/osc-footer';
import { FOOTER_LINKS } from '../../lib';

export const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <main className="container">{children}</main>
      <Footer
        isFluid={false}
        contactPhoneLink={{ url: 'tel:+1-800-446-6677', label: '+1-800-4honors' }}
        links={FOOTER_LINKS(t)}
      />
    </>
  );
};
