import type * as React from 'react';
import Header from 'next/head';
import routes from '../../routes.json';
import { env } from '@dx-ui/framework-env';

type IProps = {
  pathname?: string;
  children?: React.ReactChild;
};

export const getTitle = (pathname: string): string => {
  if (!pathname) {
    return '';
  }

  if (pathname === '/404' || pathname === '/500' || pathname === '/_error') {
    return 'Website is Unavailable';
  }

  const route = routes.find((route: { component: string }) =>
    `/my-event/${route.component}`.includes(pathname)
  ) || {
    name: '',
  };

  return route.name;
};
const Head: React.FC<React.PropsWithChildren<IProps>> = ({ pathname, children = null }) => (
  <Header>
    <link
      rel="shortcut icon"
      type="image/x-icon"
      href={`${env('HILTON_ASSETS_URI')}/modules/assets/img/HH/favicon_HH.ico`}
    />
    <title>{getTitle(pathname || '')}</title>
    <meta name="format-detection" content="telephone=no" />
    {children}
  </Header>
);

export default Head;
