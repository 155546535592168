import { sendReward } from '@dx-ui/framework-conductrics';
import set from 'lodash/set';

const navigationInteractionGoal = 'g-6gRqwMh3Pt';

export const sendInteractionReward = () => {
  sendReward(navigationInteractionGoal);
};

export function removeSpacesAndAmpersands(str: string) {
  return str.replace(/\s+|&/g, '').toLowerCase();
}

export function trackNavClickBasedOnLabel(label: string, dropdownLabel?: string) {
  const value = removeSpacesAndAmpersands(`gh_${dropdownLabel ? `${dropdownLabel}_` : ''}${label}`);

  trackNavClick(value);
}

export function trackNavClick(value: string) {
  if (window?.digitalData && window?._satellite && value) {
    set(window.digitalData, 'click.clickID', value);
    window._satellite?.track?.('global_click');
  }
}
