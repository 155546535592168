import { allEnv } from '@dx-ui/framework-env';
import { useRouter } from 'next/router';

const {
  OHW_BASE_URL,
  HONORS_SIGN_IN_URL,
  HONORS_JOIN_URL,
  SSO_IP_LOGIN_URL,
  SSO_SP_ENTITY_ID_MYEVENT,
} = allEnv();

export const lobbySignInURL = `${SSO_IP_LOGIN_URL}?PartnerSpId=${SSO_SP_ENTITY_ID_MYEVENT}&TargetResource=`;

export function useGenerateURLs() {
  const router = useRouter();
  const signInUrl = `${HONORS_SIGN_IN_URL}?forwardPageURI=${encodeURIComponent(
    (router?.query?.forwardPageURI as string) ||
      `${OHW_BASE_URL}${router.locale}${router.asPath || '/en/my-event/'}`
  )}`;
  const joinUrl = HONORS_JOIN_URL;

  return { signInUrl, joinUrl, lobbySignInURL };
}
