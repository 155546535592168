import { env } from '@dx-ui/framework-env';
import type { Link } from '@dx-ui/osc-link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

export function useHeaderLinks() {
  const router = useRouter();
  const { t } = useTranslation('osc-header');
  const language = router.locale as string;
  const OHW_ORIGIN = new URL(env('OHW_BASE_URL')).origin;
  const honorsProfileUrl = `${OHW_ORIGIN}/${language}/hilton-honors/guest/profile/`;
  const honorsActivityUrl = `${OHW_ORIGIN}/${language}/hilton-honors/guest/activity/`;
  const honorsPointsUrl = `${OHW_ORIGIN}/${language}/hilton-honors/guest/points/`;
  const findStayUrl = `${OHW_ORIGIN}/${language}/book/reservation/find/`;

  const honorsActivityLink = { url: honorsActivityUrl, label: t('activity'), isNewWindow: true };
  const honorsPointsLink = { url: honorsPointsUrl, label: t('pointsLabel'), isNewWindow: true };
  const honorsProfileLink = { url: honorsProfileUrl, label: t('profile'), isNewWindow: true };
  const additionalLinks: Link[] = [honorsActivityLink, honorsPointsLink, honorsProfileLink];

  return { additionalLinks, honorsActivityUrl, findStayUrl };
}
